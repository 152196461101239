import $ from 'jquery';


document.addEventListener('DOMContentLoaded', () => {
  // dropdown menu
  const menuScriptInit = ($menu) => {
    $menu.superfish({
      cssArrows: false,
      delay: 1000,
      animation: {
        height: 'show',
        zIndex: 1,
      },
      animationOut: {
        height: 'hide',
        zIndex: 0,
      },
      speed: 300,
      speedOut: 200,
    });
  };

  // TODO: .sf-menu - for backwards compat. Remove
  const $menu = $('.Menu--header');
  const mobileBreakpoint = 1024;
  const dropdownShowClass = 'dropdown-show';
  const menuDropdownClass = '.Menu-submenusBlock';
  // Split declarations and definitions of these vars: 1) airbnb eslint
  // requires all var to be on top of a function scope, 2) wasn't sure, how
  // menuScriptInit($menu) call changes the menu. Initially definitions were
  // after the call
  let $button;
  let $topMenu;
  let $mobileMenu;
  // Hides the mobile version of Top menu
  const hide = () => {
    $button.removeClass('active');
    $topMenu.removeClass('active');
    $mobileMenu.hide();
    $menu.find('li').removeClass(dropdownShowClass);
  };
  // Shows the mobile version of Top menu
  const show = () => {
    $button.addClass('active');
    $topMenu.addClass('active');
    $mobileMenu.show();
  };
  const clickOnCaret = function (e) {
    const minusTopValue = 10;
    if ((e.target.className === 'caret') ||
      (e.target.className === 'Menu-linkCaret')) {
      $(this).parent()
        .siblings().removeClass(dropdownShowClass)
        .end()
        .toggleClass(dropdownShowClass);

      $('html, body').stop().animate({
        scrollTop: ($(this).offset().top - minusTopValue),
      });

      return false;
    }
    return true;
  };
  const isResponsive = $('body').hasClass('responsive');

  let oldWindowWidth;
  let resizeMenu;

  menuScriptInit($menu);
  $button = $('#menu-mobile-toggle-btn');
  $menu.find('a').on('click', clickOnCaret);

  $topMenu = $('#main-menu');
  $mobileMenu = $topMenu.find(menuDropdownClass);

  $button
    .on('click', (e) => {
      if ($topMenu.hasClass('active')) {
        hide(e);
      } else {
        show(e);
      }
      return false;
    });

  // On responsive pages
  if (isResponsive) {
    oldWindowWidth = window.innerWidth;
    resizeMenu = () => {
      // If we have passed the breakpoint AND the screen was smaller
      // previously - hiding the mobile version of Top menu
      if ((oldWindowWidth < mobileBreakpoint) &&
          (window.innerWidth > mobileBreakpoint)) {
        hide();
      }
      oldWindowWidth = window.innerWidth;

      // Init the menu on wider screens
      if (window.innerWidth > mobileBreakpoint) {
        menuScriptInit($menu);
      } else {
        // de-init the menu on smaller screens
        $menu.superfish('destroy');
      }

      $('.Menu-submenusBlock, .Menu-submenuGroup')
        .siblings('.Menu-submenuLink').addClass('sf-with-ul');
    };

    resizeMenu();
    $(window).on('resize', resizeMenu);
  } else {
    // On old non-responsive .asp pages: just init the menu
    menuScriptInit($menu);
  }
  // end dropdown menu

  // ------------------------------------------------------------------------
  // Superfish simple init with universal selectors
  // ------------------------------------------------------------------------

  // E.g.: Categories list on PC main page
  $('[data-superfish]').superfish({
    popUpSelector: '[data-sf-submenu]',
    hoverClass: 'is-active',
    cssArrows: false,
    delay: 1000,
    animation: {
      height: 'show',
    },
    animationOut: {
      height: 'hide',
    },
    speed: 300,
    speedOut: 200,
  });
});

import $ from 'jquery';


/**
 * A jQuery plugin for correct from serialization.
 * `.serialize()` replaces spaces with +'s in text fields.
 */

$.fn.serializeForm = function () {
  return $.map(
    this.serializeArray(),
    (val) => [val.name, encodeURIComponent(val.value)].join('='),
  ).join('&');
};

import $ from 'jquery';
import _ from 'underscore';


$(() => {
  /**
    The elements of this object are other objects representing different types
    of Owl Carousels. Their keys:
    * elements [jQuery object] - the elements to init the carousel on
    * slideByAdjust [Int] - if set, the carousel will slide by
    *   <items in a row> minus `slideByAdjust` elements
    * settings [Object] - carousel settings sent to owlCarousel call
    * init [function] - pass `owlInit` here
  */
  const owlCarousels = {};

  //
  // Some functions
  // ------------------------------------------------------------------------

  /**
   * Carousel resize handler (we also run it on a carousel's init)
   * More on Owl events:
   * http://owlcarousel2.github.io/OwlCarousel2/docs/api-events.html
   *
   * @param {} e - Owl custom event object
   */
  function owlOnInitResize(e) {
    const carouselObj = e.relatedTarget;

    // The logic for loop-ed carousels: hiding unwanted clones, adjusting the
    // number of items to scroll, etc.
    if (carouselObj.settings.loop) {
      const slideByAdjust = (
        this.slideByAdjust !== undefined &&
        !isNaN(this.slideByAdjust) ? Number(this.slideByAdjust) : 0
      );

      // If the number of items to display is greater than the number of
      // actual items, their clones will be visible always. To prevent
      // that we are correcting the `items` options...
      if (e.page.size >= e.item.count) {
        // ... both default,..
        carouselObj.settings.items = e.item.count;
        Object.getOwnPropertyNames(
          carouselObj.options.responsive,
        ).forEach((breakpoint) => {
          // ... and those from the `responsive` breakpoints
          if (carouselObj.options.responsive[breakpoint].items > e.item.count) {
            carouselObj.options.responsive[breakpoint].items = e.item.count;
          }
        });
      } else {
        // Scroll by N-1 items (N is the number of items in a row)
        // eslint-disable-next-line no-multi-assign
        carouselObj.options.slideBy = carouselObj.settings.slideBy = (
          e.page.size > 1 ? e.page.size + slideByAdjust : 1
        );
      }

      // With invalidated width `refresh` method will recalc the items sizes
      carouselObj.invalidate('width');
      carouselObj.refresh();
    }
  }

  // Pre-initialize callback
  function onPreInitialize(e) {
    const carouselEl = e.relatedTarget.$element[0];
    const carouselObjItems = carouselEl.children;
    const orderSelector = 'data-owl-carousel-item-order';
    // Set index to scroll for item that become first on a specific width
    // (CROSS-705)
    for (let i = 0; i < carouselObjItems.length; i++) {
      const child = carouselObjItems[i];
      if (child.getAttribute(`${orderSelector}-width`)) {
        child.setAttribute(`${orderSelector}`, i);
      }
    }
  }

  /**
   * Inits a carousel
   */
  function owlInit() {
    const _this = this;
    const webFeatures = window.webFeatures;
    // So that `this` in the function would point on the settings object
    const onResizeBound = owlOnInitResize.bind(_this);

    _this.elements.each(function () {
      const carousel = $(this);
      let settings = {};

      function highlightCurrent(e, i) {
        const masterSlider = $(settings.linked);
        const index = i || 0;

        masterSlider.find('.owl-item.current').removeClass('current');
        const currentMaster = masterSlider.find('.owl-item').eq(index);
        currentMaster.addClass('current');

        carousel.find('.owl-item.current').removeClass('current');
        const currentThis = carousel.find('.owl-item').eq(index);
        currentThis.addClass('current');
      }

      settings = _.assign({}, JSON.parse(JSON.stringify(_this.settings)), {
        // So that responsive breakpoints depend on the width of THE CAROUSEL
        // ELEMENT (not window)
        responsiveBaseElement: carousel,
        // If some pagination is present in the markup
        dotsContainer: carousel[0].getAttribute('data-owl-pagination'),
        // For example, if the items and the pager are siblings
        nestedItemSelector: carousel[0].getAttribute('data-owl-item-class'),
      });

      // Older webkits seem to have problems with firing onAnimationEnd event.
      // Turning animation of the slides off for them; instead slides will
      // just be slid lift and right
      try {
        if (webFeatures.chrome && webFeatures.chrome[1] <= 28 ||
          webFeatures.android && webFeatures.android[1] <= 4.2
        ) {
          settings.animateIn = undefined;
          settings.animateOut = undefined;
        }
      } catch (e) {
        const sentry = window.Sentry;

        if (sentry) {
          sentry.withScope((scope) => {
            // Checking if some of the manipulations that featureDetect should
            // have made have actually been made
            scope.setExtra(
              'still_has_noJS_class',
              document.documentElement.className.search('is-noJs') !== -1,
            );
            // Just in case - checking if our inline JS has succeeded in
            // modifying the window object
            scope.setExtra('cub key', !!window.siteSettings.cubPublicKey);
            // And let's try sending the whole objects
            scope.setExtra('features', window.webFeatures);
            sentry.captureException(e);
          });
        }
      }

      // By defaule .owl-carousel has d:n, but we want the list/grid to be
      // visible even if there are some JS problems - so adding the class on
      // initialization
      carousel.addClass('owl-carousel');
      // Additional classes on a carousel root element
      if (_this.extraClass !== undefined) {
        carousel.addClass(_this.extraClass);
      }

      // The main carousel/slider that gets linked to another carousel
      if (carousel[0].hasAttribute('data-owl-linked')) {
        settings.linked = carousel[0].getAttribute('data-owl-linked');

        // Detecting current elements to highlight them
        carousel.on('linked.to.owl.carousel', highlightCurrent);
      }
      // The pagination carousel that gets linked to the main slider
      if (carousel[0].hasAttribute('data-owl-islinked')) {
        settings.linked = `[data-owl-linked="#${carousel[0].id}"]`;
        settings.linkedPagination = true;
        settings.onInitialized = highlightCurrent;

        // Detecting current elements to highlight them
        carousel.on('linked.to.owl.carousel', highlightCurrent);
        carousel.on('click', '.owl-item', (e) => {
          const index = [].slice.call(e.currentTarget.parentNode.children)
            .indexOf(e.currentTarget);
          carousel.trigger('linked.to.owl.carousel', [index, 300, true, true]);
        });
      }

      carousel.on('initialize.owl.carousel', onPreInitialize);
      carousel.on('initialized.owl.carousel', onResizeBound);
      carousel.on('resized.owl.carousel', onResizeBound);

      carousel.owlCarousel(settings);

      // Auto-scroll to Sponsored Item on a specific width (e.g. on mobiles)
      // (CROSS-705)
      const orderSelector = 'data-owl-carousel-item-order';
      const orderedItem = carousel.find(`[${orderSelector}-width]`);
      const orderWidthToTrigger = orderedItem &&
        orderedItem.attr(`${orderSelector}-width`);

      if (orderWidthToTrigger &&
        parseInt(orderWidthToTrigger, 10) >= $(window).width()) {
        const index = orderedItem.attr(`${orderSelector}`);
        if (index) {
          carousel.trigger('to.owl.carousel', [index, 0]);
        }
      }

      // Using autoHeight before all the media is loaded could lead to
      // calculating wrong height value. So we need to refresh on window.load
      // WARNING: won't work in iOS 8 if there are <audio> or <video> els with
      // preload="none", even inside iframes
      if (settings.autoHeight && settings.autoHeight === true) {
        window.addEventListener('load', () => {
          carousel.trigger('refresh.owl.carousel');
        });
      }
    });
  }

  //
  // Defining settings for different carousels
  // ------------------------------------------------------------------------

  // Owl Carousel for e.g. Featured Products
  owlCarousels.products = {
    elements: $('[data-owl-carousel="products"]'),
    slideByAdjust: -1,
    settings: {
      // This `margin` adds a margin-right to .owl-item-s AND - unlike margin
      // set on .owl-item via CSS, - that margin it taken into account when
      // calculating the items width
      margin: 20,
      loop: true,
      nav: true,
      dots: false,
      // the breakpoints take the carousel's 30px side margins into account
      responsive: {
        0: {
          items: 1,
        },
        420: {
          items: 2,
        },
        560: {
          items: 3,
        },
        800: {
          items: 4,
        },
      },
      responsiveClass: 'owl-responsive',
    },
    init: owlInit,
  };

  // Owl Carousel for e.g. Featured Deals (Product Deals Page)
  owlCarousels.deals = {
    elements: $('[data-owl-carousel="deals"]'),
    slideByAdjust: -1,
    settings: {
      margin: 20,
      loop: true,
      nav: true,
      dots: false,
      // the breakpoints take the carousel's 30px side margins into account
      responsive: {
        0: {
          items: 1,
        },
        360: {
          items: 2,
        },
        580: {
          items: 3,
        },
        700: {
          items: 4,
        },
        800: {
          items: 5,
        },
        1050: {
          items: 7,
        },
      },
      responsiveClass: 'owl-responsive',
    },
    init: owlInit,
  };

  // Owl Carousel for e.g. Featured Education
  owlCarousels.dual = {
    elements: $('[data-owl-carousel="dual"]'),
    slideByAdjust: -1,
    settings: {
      margin: 20,
      loop: true,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        500: {
          items: 2,
        },
      },
      responsiveClass: 'owl-responsive',
    },
    init: owlInit,
  };

  // Owl Carousel for e.g. Market Advertising full width slider
  owlCarousels.singleSlide = {
    elements: $('[data-owl-carousel="single"]'),
    settings: {
      loop: false,
      nav: true,
      dots: false,
      rewind: true,
      animateIn: 'isAnimatingFadeIn',
      animateOut: 'isAnimatingFadeOut',
      smartSpeed: 450,
      items: 1,
    },
    extraClass: 'owl-carousel--single-item',
    init: owlInit,
  };

  // Owl Carousel full width slider with thumbs-based pagination
  // E.g.: Breaking news slider
  owlCarousels.singleSlideWithDots = {
    elements: $('[data-owl-carousel="single-with-pager"]'),
    settings: {
      loop: false,
      nav: true,
      dots: true,
      rewind: true,
      animateIn: 'isAnimatingFadeInText',
      animateOut: 'isAnimatingFadeOutText',
      smartSpeed: 450,
      items: 1,
    },
    extraClass: 'owl-carousel--single-item owl-carousel--img-aspect',
    init: owlInit,
  };

  // A slider withing the content area of a Post
  owlCarousels.singleSlide = {
    elements: $('[data-owl-carousel="incontent"]'),
    settings: {
      loop: false,
      nav: true,
      dots: false,
      rewind: true,
      animateIn: 'isAnimatingFadeIn',
      animateOut: 'isAnimatingFadeOut',
      smartSpeed: 450,
      items: 1,
    },
    extraClass: 'owl-carousel--single-item owl-carousel--incontent',
    init: owlInit,
  };

  // A carousel to serve as a pagination to another carousel/slider.
  owlCarousels.paginationCarousel = {
    elements: $('[data-owl-carousel="pagination"]'),
    settings: {
      // Important: otherwise not all the main slider's items will be accessible
      slideBy: 1,
      margin: 20,
      // Important: othewise .owl-item-s will be enlarged if the number of them
      // is smaller than a corresponding `responsive :: items`
      loop: false,
      // Don't need any navigation or pagination for this one
      nav: false,
      dots: false,
      animateIn: '',
      animateOut: '',
      smartSpeed: 450,
      responsive: {
        0: {
          items: 4,
        },
        728: {
          items: 6,
        },
        620: {
          items: 5,
        },
        860: {
          items: 7,
        },
      },
    },
    extraClass: 'owl-carousel--pagination',
    init: owlInit,
  };

  // Owl Carousel for the list of logos (Market Advertising)
  owlCarousels.marketLogos = {
    elements: $('[data-owl-carousel="logos"]'),
    slideByAdjust: -1,
    settings: {
      loop: true,
      nav: true,
      dots: false,
      navSpeed: 150,
      responsive: {
        0: {
          items: 1,
        },
        300: {
          items: 2,
        },
        520: {
          items: 3,
        },
        600: {
          items: 4,
        },
        800: {
          items: 5,
        },
        1050: {
          items: 7,
        },
      },
      responsiveClass: 'owl-responsive',
    },
    init: owlInit,
  };

  // Top article carousels
  owlCarousels.articles = {
    elements: $('[data-owl-carousel="article"]'),
    slideByAdjust: -1,
    settings: {
      loop: true,
      autoHeight: true,
      nav: true,
      dots: false,
      navSpeed: 300,
      // The breakpoints values are quite hight because there can be a video
      // thumb as the left part of an item
      responsive: {
        0: {
          items: 1,
        },
        560: {
          items: 2,
        },
        720: {
          items: 3,
        },
        920: {
          items: 4,
        },
      },
      responsiveClass: 'owl-responsive',
    },
    extraClass: 'owl-carousel--borders',
    init: owlInit,
  };

  // Running stuff
  Object.keys(owlCarousels).forEach((key) => {
    owlCarousels[key].init();
  });
});

/* global grecaptcha, mediaUtils  */

// Just temporarily comment this to make old Gulp build work (CROSS-441)
import $ from 'jquery';
import 'jquery-validation';
import _ from 'underscore';


document.addEventListener('DOMContentLoaded', () => {
  const formValidatorObject = {
    nameRegExp: /^[a-zA-Z0-9_.\- '"]+$/,
    phoneRegExp: /^\d{3}-\d{3}-\d{4}$/,
    departmentRegExp: /^[a-zA-Z0-9,'"./\- ]+$/,
    emptyMessageSelect: 'Please make your choice',
    emptyMessage(fieldName) {
      const fieldNameNew = (fieldName && !fieldName.length) ?
        'field' : fieldName;
      return `The ${fieldNameNew} is required`;
    },
    success(form) {
      form
        .removeClass('waiting is-processing')
        .addClass('success is-success');

      // apply GA
      if ((form.attr('data-analytics') === 'true') && (window.dataLayer)) {
        window.dataLayer.push({
          event: 'AJAXForm',
        });
      }

      const unIdxArray = form.serializeArray();
      const idxArray = {};

      $.each(unIdxArray, function () {
        idxArray[this.name] = this.value || '';
      });

      mediaUtils.sendLyticsData(idxArray);
    },
    error(form) {
      form
        .removeClass('waiting is-processing')
        .addClass('error is-error');
    },
    formValidateRules: undefined,
    leadFormValidateRules: undefined,
  };

  // This will contain pairs of <instance name>: <validation settings obj>,
  // while the object above, I think, should be left with helpers. To avoid
  // mixing different entities
  const validateSettings = {};

  // I want to try an alternative approach to setting up validation. Because
  // setting explicit rules/messages for each and every control name is
  // rather unflexible. So what the would-be tech is is:
  // 1) Use classnames to tell the validator what rules to apply. The rules
  // should be added beforehand for the classname. (unfortunately, the
  // plugin doesn't allow for other selectors, i.e. data-attrs)
  // 2) Use controls' attributes where appropriate: required, maxlength
  // /dryoma

  // -----------------------------------------------------------------------
  // Adding custom validation methods
  // -----------------------------------------------------------------------

  // Overriding the built-in `required` method - to warn on fields filled
  // with only spaces
  $.validator.methods.required = function (value, element, param) {
    let val;
    // Check if dependency is met
    if (!this.depend(param, element)) { return 'dependency-mismatch'; }

    if (element.nodeName.toLowerCase() === 'select') {
      // Could be an array for select-multiple or a string, both are fine
      val = $(element).val();
      return val && val.length > 0;
    }
    if (this.checkable(element)) {
      return this.getLength(value, element) > 0;
    }
    return value.trim().length > 0;
  };

  // checking multiple addesses
  $.validator.addMethod(
    'multiemail',
    function (value, element) {
      const emails = value.split(/[,]+/); // split element by ,
      let i;
      let valid = true;
      let valueLocal;
      // return true on optional element
      if (this.optional(element)) { return true; }

      for (i in emails) {
        if (Object.prototype.hasOwnProperty.call(emails, i)) {
          valueLocal = emails[i];
          valid = valid &&
            $.validator.methods.email.call(this, $.trim(valueLocal), element);
        }
      }
      return valid;
    },
    $.validator.messages.email,
  );

  // Check the value against a regular expression
  // TODO: maybe add more details in the message about what 'valid' is?
  $.validator.addMethod('regexp', function (value, element, param) {
    let regex = param;
    // Do nothing if the field is not required
    if (this.optional(element)) { return true; }
    if (typeof regex === 'string') {
      regex = new RegExp(`^(?:${regex})$`);
    }
    return regex.test(value);
  }, 'Please enter a valid value');

  // -----------------------------------------------------------------------
  // Custom validation class-rules
  // -----------------------------------------------------------------------

  // Complex rules (those consisting of multiple methods, e.q. minlength AND
  // maxlength); and predefined regexps, to avoid specifying them in a data
  // attr every time
  // Class name pattern: `js-validateYourRuleName`
  $.validator.addClassRules({
    // First names, Last names
    'js-validateName': {
      regexp: formValidatorObject.nameRegExp,
    },
    // Department name
    'js-validateDept': {
      regexp: formValidatorObject.departmentRegExp,
    },
    'js-validatePhone': {
      regexp: formValidatorObject.phoneRegExp,
    },
  });

  // A basic validation object with default classes/handlers/messages. Can
  // be used by itself, or as a base for custom validation settings.
  // NO submitHandler/errorHandler are specified: they are either already
  // implemented (e.g. via `data-ajax-form`) or not needed (i.e. default
  // behavior)
  validateSettings.basic = {
    errorClass: 'Form-hint Form-hint--error',
    errorElement: 'small',
    // By default (if not set) this function adds the errorClass to the
    // invalid field either. We don't want that
    highlight() {},
  };

  // simple form validating rules
  formValidatorObject.formValidateRules = (((parent) => ({
    errorClass: 'Form-hint Form-hint--error',
    errorElement: 'small',

    // By default (if not set) this function adds the errorClass to the
    // invalid field either. We don't want that
    highlight() {},

    messages: {
      first: {
        required: parent.emptyMessage('first name'),
      },
      last: {
        required: parent.emptyMessage('last name'),
      },
      email: {
        required: parent.emptyMessage('email'),
      },
    },

    rules: {
      first: {
        regexp: parent.nameRegExp,
      },
      last: {
        regexp: parent.nameRegExp,
      },
    },
  }))(formValidatorObject));

  // lead form validating rules
  formValidatorObject.leadFormValidateRules = (
    ((parent) => _.assign({}, parent.formValidateRules, {
      messages: {
        department: {
          required: parent.emptyMessage('department'),
        },
        departmentSize: {
          required: parent.emptyMessage('department size'),
        },
        zip: {
          required: parent.emptyMessage('zip'),
        },
        phone: {
          required: parent.emptyMessage('phone'),
        },
        authorized: {
          required: parent.emptyMessageSelect,
        },
        PurchasingTimeframe: {
          required: parent.emptyMessageSelect,
        },
      },
      rules: {
        department: {
          regexp: parent.departmentRegExp,
        },
        departmentSize: {
          required: true,
        },
        zip: {
          digits: true,
        },
        phone: {
          regexp: parent.phoneRegExp,
        },
        authorized: {
          required: true,
        },
        PurchasingTimeframe: {
          required: true,
        },
      },
    })
    )(formValidatorObject));

  if ($.fn.validate) {
    // The vary basic validation. Only default fields (required, etc.).
    // NO submit/error handlers. There are either already some handlers
    // attached to the form (e.g. via `data-ajax-form`), or a handler is not
    // needed
    // TODO: to replace .request-info-form, ...
    $('[data-validate="basic"]').each(function () {
      $(this).validate(validateSettings.basic);
    });

    $('#modal-email').on('show.bs.modal', function (event) {
      // Button that triggered the modal
      const button = $(event.relatedTarget);
      const modal = $(this);
      const url = '/emailtofriend/';

      // remove previous mark up
      modal.find('[data-modal-contents]').html('');

      $.get(url, {
        project: button.data('project'),
        dialogTitle: button.data('dialogTitle'),
        payload: button.data('payload'),
      }, (data) => {
        modal.find('[data-modal-contents]').html(data);

        modal.find('[data-modal-title]').html(button.data('title'));

        $(window).trigger('resize.modal');

        $('#form-send-email').validate({
          errorClass: 'error Form-hint Form-hint--error',
          errorElement: 'small',
          highlight() {},
          messages: {
            fromEmail: {
              required: formValidatorObject.emptyMessage('email'),
            },
            toEmail: {
              required: formValidatorObject.emptyMessage('email'),
            },
          },
          rules: {
            toEmail: {
              multiemail: false,
            },
          },
          submitHandler(form) {
            const $form = $(form);
            const formData = $form.serializeArray();
            // add spinner
            $form.addClass('waiting is-processing');

            $.ajax({
              url,
              data: formData,
              type: 'POST',
              success(reqData) {
                if (reqData.success) {
                  formValidatorObject.success($form);
                } else {
                  if (window.grecaptcha) {
                    grecaptcha.reset();
                  }
                  formValidatorObject.error($form);
                }
              },
              /**
               * error handler
               *
               * @param {} [xhr] - optional, response object
               * @param {} [status] - optional, response status code
               */
              error() {
                if (window.grecaptcha) {
                  grecaptcha.reset();
                }
                formValidatorObject.error($form);
              },
            });
          },
        });
      });
    });

    $('[data-validate="subscription"]').validate({
      errorClass: 'error Form-hint Form-hint--error',
      errorElement: 'small',
      highlight() {},
      messages: {
        email: {
          required: formValidatorObject.emptyMessage('email'),
        },
      },
      submitHandler(form) {
        const formNew = form;
        formNew.action = (
          `${form.action}?email=${encodeURIComponent(form.email)}`
        );
        formNew.submit();
      },
    });

    $('#search-grants, #search-page').validate({
      errorClass: 'error Form-hint Form-hint--error',
      errorElement: 'small',
      highlight() {},
      messages: {
        gr_keyword: {
          required: formValidatorObject.emptyMessage('search clause'),
        },
      },
    });
  }

  window.formValidatorObject = formValidatorObject;
});

// Some fields should submit their form on change
$(document).on('change', '[data-form-send-on-change]', function () {
  // Using the jQuery trigger method as vanilla form.submit() doesn't fire
  // a submit event (surprisingly)
  $(this.form).trigger('submit');
});

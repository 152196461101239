import $ from 'jquery';


document.addEventListener('DOMContentLoaded', () => {
  // modal init
  (function modalInit() {
    function resetForm($form) {
      if ($form.length) {
        $form.validate().resetForm();
        $form[0].reset();
      }
    }

    $('.modal, .Modal')
      .on('show.bs.modal', function () {
        const commentId = $(this.element).data('commentId');

        if (!isNaN(parseInt(commentId, 10))) {
          $(this).find('form')[0].commentId.value = commentId;
        }

        // modalOnResize();
      })
      .on('hidden.bs.modal', function () {
        resetForm($(this).find('form'));
      });

    $('#popup_container').addClass('in');

    window.modalInitExecuted = true;
  }());
  // end modal init
});
